<template>
  <div>404</div>
</template>

<script>
export default {

  name: 'Page404',

  methods: {
    
    /* **** back **** */
    back() {
      if(this.$route.query.noGoBack) {
        this.$router.push({ path: '/monitor' });
      } else {
        this.$router.go(-1);
      }
    },
    
  },

};
</script>
